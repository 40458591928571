import Divider from "@mui/material/Divider";
const DividerComponent = ({ sx }) => {
    return (
        <Divider
            sx={{
                "&.MuiDivider-root": {
                    borderColor: "#DDD",
                },
                ...sx
            }}
        />
    )
}

export default DividerComponent;
