import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";

import * as Api from "../../api";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import RestaurantHeader from "../../components/RestaurantHeader";
import SwitchInput from "../../components/SwitchInput";

import { connect } from "react-redux";
import { getRestaurantData, getFeaturesList, addFeatureToRestaurant, removeFeatureFromRestaurant } from '../../api';
import { updateRestaurantData, updateFeaturesList } from "../../redux/actions/userDataActions";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const getSelectedFeaturesObject = (featuresList) => {
    const ids = {};
    for (const featureId of featuresList) ids[featureId] = true;
    return ids;
}

const FeaturesList = (props) => {
    const navigate = useNavigate();
    const { rest_id } = useParams();
    const [availableFeatureFlags, setAvailableFeatureFlags] = useState([]);
    const [selectedFeatureIds, setSelectedFeatureIds] = useState(getSelectedFeaturesObject(props.featuresList));
    const [loading, setLoading] = useState(false);
    const [msgAlert, setMsgAlert] = useState({
        open: false,
        message: "",
        msgType: "error",
    });

    useEffect(() => {
        getRestaurantData(rest_id).then((response) => {
            if (response.success) {
                props.updateRestaurantData(response.data);
            }
        });
        getFeatures();
    }, []);

    useEffect(() => {
        setSelectedFeatureIds(getSelectedFeaturesObject(props.featuresList));
    }, [props.featuresList]);

    const handleFeatureToggle = async (feature, checked) => {
        setLoading(true);
        const response = checked ? await addFeatureToRestaurant(rest_id, [feature._id]) : await removeFeatureFromRestaurant(rest_id, [feature._id]);
        setLoading(false);
        if (response.success) {
            setSelectedFeatureIds(checked ? { ...selectedFeatureIds, [feature._id]: true } : { ...selectedFeatureIds, [feature._id]: false });
            props.updateFeaturesList(checked ? [...props.featuresList, feature._id] : props.featuresList.filter(id => id !== feature._id));
            setMsgAlert({
                open: true,
                message: `Feature ${checked ? "added" : "removed"} successfully`,
                msgType: "success"
            })
        } else {
            setMsgAlert({
                open: true,
                message: response.msg,
                msgType: "error"
            })
        }
    }

    const getFeatures = async () => {
        setLoading(true);
        const response = await getFeaturesList();
        setLoading(false);
        if (response.success) {
            setAvailableFeatureFlags(response.data.rows);
        } else {
            setMsgAlert({
                open: true,
                message: response.msg,
                msgType: "error"
            })
        }
    }

    return (
        <Container
            page={"restaurants"}
            searchVal={""}
            onSearch={() => null}
            searchLabel=""
        >
            {loading ? (
                <Loader height={{ height: window.innerHeight - 150 }} />
            ) : (
                <Grid container item xs={12}>
                    <Grid item xs={12} mb={2}>
                        <RestaurantHeader
                            title={"Features List"}
                            onBackBtnClick={() => navigate("/restaurant/" + rest_id)}
                        />
                    </Grid>
                    <Grid xs={12} sx={styles.mainView}>
                        <Grid container xs={12}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '0.75rem',
                                padding: '1rem 2rem'
                            }}
                        >
                            {availableFeatureFlags.map((feature) => {
                                return (
                                    <Grid sx={{ width: "fit-content", display: "flex", gap: '2rem' }}>
                                        <FormGroup key={feature._id}>
                                            <FormControlLabel
                                                control={
                                                    <Box className={styles?.["switch-input-view"]}>
                                                        <SwitchInput
                                                            checked={selectedFeatureIds[feature._id]}
                                                            onChange={(val) => handleFeatureToggle(feature, val)}
                                                        />
                                                    </Box>
                                                }
                                                sx={{
                                                    color: props.themeMode === "dark" ? '#ffffff' : '#000000',
                                                    margin: 0,
                                                    '& .MuiTypography-root': {
                                                        marginLeft: '0.5rem',
                                                        whiteSpace: 'nowrap'
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                        <Typography>{feature.label}</Typography>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <AlertMsg
                msgAlert={msgAlert}
                onCloseAlertMsg={() =>
                    setMsgAlert({ open: false, message: "", msgType: "error" })
                }
            />
        </Container >
    )
}

const mapStateToProps = (state) => ({
    featuresList: state.userData.restaurantData?.featureFlags ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    updateFeaturesList: (featuresList) => dispatch(updateFeaturesList(featuresList)),
    updateRestaurantData: (data) => dispatch(updateRestaurantData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeaturesList);

const styles = {
    mainView: {
        backgroundColor: "#ffffff",
        minHeight: "calc(100vh - 180px)",
        borderRadius: "8px",
        px: 3,
        pt: 2
    }
}
