import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from '@mui/material';

import { ReactComponent as ListView } from "../../assets/images/list_view.svg";
import Loader from "../../components/Loader";
import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import { BTN_COLOR, BTN_TEXT_COLOR } from "../../constants";
const ICON_SIZE = 25;

const settings_list = [
    {
        page: "features_list ",
        label: "Features List",
        href: "/global_settings/features_list",
        icon: <ListView color="#ffffff" sx={{ fontSize: ICON_SIZE }} width={ICON_SIZE} height={ICON_SIZE} />,
    },
]

const GlobalSettings = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [msgAlert, setMsgAlert] = useState({
        open: false,
        msg: "",
        type: "success",
    });

    const navigate = useNavigate();

    const onSearchRestaurant = (val) => {
        setSearchText(val);
    };

    const onCloseAlertMsg = () => {
        setMsgAlert({ open: false, message: "", msgType: "error" });
    };


    return (
        <Container
            page={"global_settings"}
            searchVal={""}
            onSearch={() => null}
            searchLabel=""
        >
            {isLoading ? (
                <Loader height={{ height: window.innerHeight - 150 }} />
            ) : (
                <>
                    <Box sx={styles.titleMainBox} mb={1}>
                        <Typography variant={"h6"} sx={styles.restaurantText}>
                            Global Settings
                        </Typography>
                    </Box>

                    <Grid container item xs={12} p={3} sx={styles.settingsMainDiv}>
                        <Grid container item xs={12} md={10} sx={styles.settingsBtnMainDiv}>
                            {settings_list.map((x, i) => {
                                return (
                                    <Grid key={i} item xs={6} md={3}>
                                        <Box
                                            sx={styles.settingsBtnDiv}
                                            onClick={() => navigate(x.href)}
                                        >
                                            <Box>{x.icon}</Box>
                                            <Typography sx={styles.settingsBtnDivText}>
                                                {x.label}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </>
            )}
            <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={onCloseAlertMsg} />
        </Container>
    )
}

export default GlobalSettings

const styles = {
    settingsMainDiv: {
        backgroundColor: "#FFF",
        borderRadius: "8px",
    },
    settingsBtnMainDiv: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    settingsBtnDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: BTN_COLOR,
        borderRadius: "8px",
        cursor: "pointer",
        width: { xs: "98%", md: "90%" },
        height: 100,
        px: 1.5,
        py: 1.3,
        mb: 3,
    },
    settingsBtnDivText: {
        color: BTN_TEXT_COLOR,
        fontSize: 12.5,
        fontFamily: "GilroySemiBold",
        textAlign: "center",
    },
}
