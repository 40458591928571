import * as actionTypes from '../actions/types';

const initialState = {
  userId: '',
  userToken: '',
  refreshToken: '',
  drawerOpen: true,
  restaurantData: null,
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_TOKEN:
      return {
        ...state,
        userToken: action.payload,
      };

    case actionTypes.UPDATE_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload,
      };

    case actionTypes.UPDATE_DRAWER_OPEN:
      return {
        ...state,
        drawerOpen: action.payload,
      };

    case actionTypes.UPDATE_RESTAURANT_DATA:
      return {
        ...state,
        restaurantData: action.payload,
      };

    case actionTypes.UPDATE_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };

    case actionTypes.UPDATE_FEATURES_LIST:
      return {
        ...state,
        restaurantData: {
          ...state.restaurantData,
          featureFlags: action.payload
        }
      }

    case 'LOG_OUT':
      return {
        ...state,
        userId: '',
        userToken: '',
        refreshToken: '',
        drawerOpen: true,
        restaurantData: null,
      };
  }
  return state;
};

export default userDataReducer;
