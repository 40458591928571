import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import SettingTextField from "./SettingTextField";
import UploadLogo from "./UploadLogo";

import styles from "./SocialMedia.module.css";

const CategoryGroup = (props) => {
    let { data, error } = props;

    const onDelete = () => {
        props.onDelete(props.index);
    };

    const onUpdate = (field, value) => {
        props.onUpdate(field, value, props.index);
    };

    return (
        <Grid
            item
            container
            xs={12}
            justifyContent={"space-between"}
            sx={{ borderBottom: "2px solid #AAA" }}
            pb={2}
            mb={2}
        >
            <Grid item xs={12} md={5.8} mt={2}>
                <Box mb={1}>
                    <Typography className={styles.textInputLabel}>Group Name</Typography>
                </Box>
                <Box>
                    <SettingTextField
                        page={"CategoryGroup"}
                        editable={true}
                        value={data?.name}
                        setValue={(val) => onUpdate("name", val)}
                        placeholder={""}
                        required={true}
                        variant={"filled"}
                        border={"1px solid #AAA"}
                        error={error?.categoryGroups?.[props.index]?.name}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={5.8} mt={2}>
                <Box mb={1}>
                    <Typography className={styles.textInputLabel}>Categories</Typography>
                </Box>
                <Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: '0.75rem', width: "100%" }}>
                        <Stack direction="row" spacing={1} sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-start", flexWrap: "wrap", gap: "2px 0.325rem" }}>
                            {data.categories.map(category => <Chip
                                key={category._id}
                                style={{ marginLeft: 0 }}
                                label={category.name}
                                variant="outlined"
                                onDelete={() => props.onRemoveCategory(data, category, props.index)}
                            />)}
                        </Stack>
                        <Chip
                            sx={{
                                fontWeight: '500',
                                lineHeight: '1.75rem',
                                letterSpacing: '0.02857em',
                                textTransform: 'uppercase',
                                border: '1px solid'
                            }}
                            label="Edit"
                            variant="outlined"
                            onClick={() => props.onCategoryBtnClick(data, props.index)}
                        />
                    </Box>
                    <Box>
                        {error?.categoryGroups?.[props.index]?.categories && (
                            <Typography className={styles.errorText}>
                                {error?.categoryGroups?.[props.index]?.categories}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12} md={5.8} mt={2}>
                <Box mb={1}>
                    <Typography className={styles.textInputLabel}>Image width</Typography>
                </Box>
                <Box>
                    <SettingTextField
                        page={"CategoryGroup"}
                        editable={true}
                        value={data.imageWidth || ""}
                        setValue={(val) =>
                            onUpdate("imageWidth", val.replace(/[^0-9.]/g, ""))
                        }
                        placeholder={""}
                        required={true}
                        variant={"filled"}
                        border={"1px solid #AAA"}
                        error={error?.categoryGroups?.[props.index]?.imageWidth}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={5.8} mt={2}>
                <Box mb={1}>
                    <Typography className={styles.textInputLabel}>Image height</Typography>
                </Box>
                <Box>
                    <SettingTextField
                        page={"CategoryGroup"}
                        editable={true}
                        value={data.imageHeight || ""}
                        setValue={(val) =>
                            onUpdate("imageHeight", val.replace(/[^0-9.]/g, ""))
                        }
                        placeholder={""}
                        required={true}
                        variant={"filled"}
                        border={"1px solid #AAA"}
                        error={error?.categoryGroups?.[props.index]?.imageHeight}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={5.8} mt={2}>
                <Box mr={2} mb={1}>
                    <UploadLogo
                        type={"CategoryGroup" + props.index}
                        uploadFile={props.uploadLogoFile}
                        imageLoading={props.imageLoading}
                        logoImg={
                            typeof data?.img == "string" && data?.img
                                ? props.getImgDetail(data?.img, "CategoryGroup")
                                : data?.img
                                    ? data?.img
                                    : ""
                        }
                        imageType={props.imageType}
                        placeholder={"Category Group LOGO"}
                        showViewUploadIcon={true}
                        onRemove={() => onUpdate("img", "")}
                    />
                    {error?.categoryGroups?.[props.index]?.image && (
                        <Typography className={styles.errorText}>
                            {error?.categoryGroups?.[props.index]?.image}
                        </Typography>
                    )}
                </Box>
            </Grid>
            <Grid container item xs={12} mt={2} justifyContent={"flex-end"}>
                <Button
                    type="button"
                    disabled={props.imageLoading}
                    className={styles.deleteBtn}
                    sx={{
                        opacity: props.imageLoading ? 0.2 : 1,
                    }}
                    onClick={props.imageLoading ? null : onDelete}
                >
                    Delete
                </Button>
            </Grid>
        </Grid>
    );
};

export default CategoryGroup;
