import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Box, Button } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

const CategoryGroupDialog = ({ data: { restaurantCategories, categories, categoriesInGroupsList }, handleToggleCategory, handleClose, handleSave }) => {
    return (
        <Box sx={{ padding: '1.5rem' }}>
            <DialogTitle id="alert-dialog-title">
                Choose Categories
            </DialogTitle>
            <FormGroup>
                {restaurantCategories.map(category => {
                    const isChecked = categories.find(cat => cat._id === category._id) ? true : false;
                    return <FormControlLabel
                        required
                        control={<Checkbox
                            checked={isChecked}
                            onChange={() => handleToggleCategory(category, isChecked)}
                            disabled={!isChecked && categoriesInGroupsList.has(category._id)}
                        />}
                        label={category.name}
                    />
                })}
            </FormGroup>

            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Box>
    )
}

export default React.memo(CategoryGroupDialog);
