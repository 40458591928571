import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Modal from '@mui/material/Modal';

import { createFeature, getFeaturesList, deleteFeature, updateFeature } from '../../api';
import { ReactComponent as AddIcon } from "../../assets/images/add_plus_white.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete1.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import RestaurantHeader from "../../components/RestaurantHeader";
import ConfirmAlert from '../../components/restaurant/ConfirmAlert';
import SettingTextField from "../../components/SettingTextField";

const AddModalModes = {
    ADD: "ADD",
    EDIT: "EDIT"
}

const getDefaultAlert = () => ({
    open: false,
    title: "",
    content: "",
    firstBtnText: "",
    secondBtnText: "",
    onCancel: null,
    onConfirm: null,
})

const getDefaultModalConfig = () => ({
    open: false,
    mode: AddModalModes.ADD,
    handleClose: null,
    handleSave: null,
    item: null
});

const AddFeatureModal = ({ config }) => {
    const [newFeatureName, setNewFeatureName] = useState("");
    const onSaveBtnClick = () => {
        if (newFeatureName.trim() === "") return;
        config.handleSave(config.mode === AddModalModes.EDIT ? { ...config.item, label: newFeatureName } : newFeatureName);
        setNewFeatureName("");
    };

    useEffect(() => {
        setNewFeatureName(config.item ? config.item.label : "");
    }, [config.item]);

    return (<Modal
        open={config.open}
        onClose={config.handleClose}
    >
        <Box sx={{ ...featureListStyles.addModal, width: { xs: "calc(100% - 2rem)", sm: "36rem" } }}>
            <h2 id="add-feature-modal-title">{`${config.mode === AddModalModes.EDIT ? "Edit" : "Add"} Feature`}</h2>

            <Grid item xs={12}>
                <SettingTextField
                    page={"feature-list"}
                    value={newFeatureName}
                    setValue={(val) => { setNewFeatureName(val) }}
                    border={"1px solid #AAA"}
                    placeholder={""}
                    labelStyle={{ fontSize: 14, fontFamily: "GilroySemiBold" }}
                    label="Feature Name"
                    required={true}
                    variant={"filled"}
                />
            </Grid>

            <Grid container item xs={12} mt={2} sx={featureListStyles.btnMainView}>
                <Button type="button" sx={{ ...featureListStyles.modalBtn, ...featureListStyles.editBtn }} onClick={config.handleClose}>
                    Cancel
                </Button>
                <Button
                    type="button"
                    sx={{ ...featureListStyles.modalBtn, ...featureListStyles.saveBtn }}
                    onClick={onSaveBtnClick}
                >
                    Save
                </Button>
            </Grid>
        </Box>
    </Modal>);
}

const FeaturesList = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [featuresList, setFeaturesList] = useState([]);
    const [msgAlert, setMsgAlert] = useState({
        open: false,
        message: "",
        msgType: "error",
    });
    const [confirmAlert, setConfirmAlert] = useState(getDefaultAlert());
    const [addModalConfig, setAddModalConfig] = useState(getDefaultModalConfig());

    useEffect(() => {
        getFeatures();
    }, []);

    const setDefaultModalConfig = () => {
        setAddModalConfig(getDefaultModalConfig());
    }

    const handleFeatureDelete = (item) => {
        setConfirmAlert({
            open: true,
            title: "Confirm Delete",
            content: `Are you sure you want to delete ${item.label}?`,
            firstBtnText: "Cancel",
            secondBtnText: "Delete",
            onCancel: () => setConfirmAlert(getDefaultAlert()),
            onConfirm: () => deleteTheFeature(item._id),
        })
    }

    const handleFeatureEdit = (item) => {
        setAddModalConfig({
            open: true,
            mode: AddModalModes.EDIT,
            item: item,
            handleClose: setDefaultModalConfig,
            handleSave: updateTheFeature,
        });
    }

    const updateTheFeature = async (item) => {
        setDefaultModalConfig();
        setLoading(true);

        const response = await updateFeature(item);

        setLoading(false);
        if (response.success) {
            setFeaturesList(featuresList.map((f) => f._id === item._id ? item : f));
            setMsgAlert({
                open: true,
                message: "Feature updated successfully",
                msgType: "success"
            })
        } else {
            setMsgAlert({
                open: true,
                message: response.msg,
                msgType: "error"
            })
        }
    }


    const deleteTheFeature = async (id) => {
        setBtnLoading(true);
        const response = await deleteFeature(id);
        setBtnLoading(false);
        setConfirmAlert({
            open: false,
            title: "",
            content: "",
            firstBtnText: "",
            secondBtnText: "",
            onCancel: null,
            onConfirm: null,
        })
        if (response.success) {
            setFeaturesList(featuresList.filter((item) => item._id !== id));
            setMsgAlert({
                open: true,
                message: "Feature Deleted Successfully",
                msgType: "success"
            })
        } else {
            setMsgAlert({
                open: true,
                message: response.msg,
                msgType: "error"
            })
        }
    }

    const handleAddFeature = () => {
        setAddModalConfig({
            open: true,
            mode: AddModalModes.ADD,
            handleClose: setDefaultModalConfig,
            handleSave: saveFeature
        });
    }

    const saveFeature = async (featureName) => {
        setDefaultModalConfig();
        setLoading(true);

        const response = await createFeature(featureName);

        setLoading(false);
        if (response.success) {
            setFeaturesList([...featuresList, response.data]);
            setMsgAlert({
                open: true,
                message: response.msg,
                msgType: "success"
            })
        } else {
            setMsgAlert({
                open: true,
                message: response.msg,
                msgType: "error"
            })
        }
    }

    const getFeatures = async () => {
        setLoading(true);
        const response = await getFeaturesList();
        setLoading(false);
        if (response.success) {
            setFeaturesList(response.data.rows);
        } else {
            setMsgAlert({
                open: true,
                message: response.msg,
                msgType: "error"
            })
        }
    }

    return (
        <Container
            page={"global_settings"}
            searchVal={""}
            onSearch={() => null}
            searchLabel=""
        >
            {loading ? (
                <Loader height={{ height: window.innerHeight - 150 }} />
            ) : (
                <Grid container item xs={12}>
                    <Grid container item xs={12} display={"flex"} mb={1}>
                        <Grid item xs={10} mb={"1rem"}>
                            <RestaurantHeader
                                title={"Features List"}
                                onBackBtnClick={() => navigate("/global_settings/")}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Box sx={featureListStyles.btnView}>
                                <Box
                                    sx={featureListStyles.editInfoDiv}
                                    mr={1}
                                    onClick={handleAddFeature}
                                >
                                    <AddIcon width={14} height={14} />
                                    <Typography sx={featureListStyles.editInfoText} ml={0.5}>
                                        Add Feature
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container sx={{ ...featureListStyles.mainView, px: 3, py: 2, margin: 0 }} >

                        {featuresList.length === 0 ? <div>No Features. Create a new one</div> :
                            <Box container sx={featureListStyles.listCard}>
                                {
                                    featuresList.map((item) =>
                                        <Box item key={item._id} sx={featureListStyles.listCardItem}>
                                            <Typography>{item.label}</Typography>

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '0.5rem'
                                            }}>
                                                <EditIcon sx={{ cursor: "pointer" }}
                                                    width={20}
                                                    height={20}
                                                    onClick={() => handleFeatureEdit(item)}

                                                />
                                                <DeleteIcon
                                                    width={20}
                                                    height={20}
                                                    onClick={() => handleFeatureDelete(item)}
                                                />
                                            </Box>
                                        </Box>
                                    )
                                }
                            </Box>
                        }
                    </Grid>
                </Grid>
            )}
            <AlertMsg
                msgAlert={msgAlert}
                onCloseAlertMsg={() =>
                    setMsgAlert({ open: false, message: "", msgType: "error" })
                }
            />
            <ConfirmAlert
                confirmDelete={confirmAlert.open}
                title={confirmAlert.title}
                content={confirmAlert.content}
                firstBtn={confirmAlert.firstBtnText}
                secondBtn={btnLoading ? "Please Wait" : confirmAlert.secondBtnText}
                btnLoading={btnLoading}
                onCloseConfirmDelete={confirmAlert.onCancel}
                onConfirmDelete={confirmAlert.onConfirm}
            />

            <AddFeatureModal config={addModalConfig} />

        </Container >
    )
}

export default FeaturesList

const featureListStyles = {
    mainView: {
        backgroundColor: "#ffffff",
        minHeight: "calc(100vh - 180px)",
        borderRadius: "8px"
    },
    btnMainView: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0.8rem',
        mt: '3rem'
    },
    modalBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 'calc(50% - 0.4rem)',
        height: "2.25rem",
        padding: "0 18px",
        fontSize: "14px",
        fontFamily: "GilroySemiBold",
        cursor: "pointer",
        borderRadius: "8px",
    },
    editBtn: {
        backgroundColor: "#ffffff",
        border: "2px solid #000000",
        color: "#000000",
    },
    saveBtn: {
        backgroundColor: "#000000",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#000000",
            color: "#ffffff",
        }
    },
    labelStyle: {
        fontSize: 14,
        fontFamily: "GilroySemiBold",
    },
    addModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        outline: 'none',
        border: "none",
        borderRadius: '12px',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: '3rem',
    },
    listCard: {
        width: "100%",
        maxWidth: "40rem",
        margin: "0 auto",
    },
    listCardItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "#F5F5FA",
        borderRadius: "7px",
        margin: "0.5rem 0",
        padding: "0.75rem",
    },
    btnView: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    editInfoDiv: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#000",
        borderRadius: 20,
        cursor: "pointer",
        px: 1.5,
        py: 0.8,
        height: 36,
        minWidth: 100,
    },
    editInfoText: {
        color: "#FFF",
        fontSize: 12.5,
        fontFamily: "GilroySemiBold",
        marginLeft: 1,
        whiteSpace: 'nowrap'
    },

};